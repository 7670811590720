<template>
  <div class="eagleeyeForm-container">
    <form-panel ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore">
        <col2-block title="基础信息">
          <col2-item>
            <el-form-item label="鹰眼组名称" :rules="[{ required: true, message: '请输入鹰眼组名称', trigger: 'blur' }]" prop="groupName">
              <v-input v-model="form.groupName"></v-input>
            </el-form-item>
            <el-form-item label="使用者类型" :rules="[{ required: true, message: '请选择使用者类型', trigger: 'change' }]" prop="groupType">
              <v-select v-model="form.groupType" :options="groupTypeOps"></v-select>
            </el-form-item>
            <el-form-item label="所属项目" :rules="[{ required: true, message: '请选择所属项目', trigger: 'change' }]" prop="communityId">
              <v-select2  placeholder="所属项目" :disabled="disabledCommunity" v-model="form.communityId" v-bind="communityParams"  @onChange="getCommunityId"></v-select2>
            </el-form-item>
          <el-form-item label="摄像头" prop="eagleeye">
            <chosenListPanel :list.sync="eagleeye" @select="choseRelationInfo"></chosenListPanel>
            <multiSelect
            :isShow.sync="isShowMultiSelect"
            :searchUrl="multiSelectUrl"
            :headers="multiSelectHeader"
            :backFill.sync="eagleeye"
            :searchParams="searchelseParams"
            :responseParams="responseParams"
            :responseKey="responseKey"
            title="鹰眼摄像列表"
            :handleData="formatter"
            :extraParams="extraParams"
            @callback="multiSelectcallback">
              <template #searchSlot>
                <v-input v-model="searchelseParams.deviceName" label="设备名称"></v-input>
                <v-input v-model="searchelseParams.cameraName" label="通道名称"></v-input>
                <v-select v-model="searchelseParams.status" label="状态" :options="searchStatus"></v-select>
              </template>
            </multiSelect>
          </el-form-item>
            <el-form-item label="描述" :rules="[{ required: true, message: '请输入描述', trigger: 'blur' }]" prop="description">
              <v-textarea placeholder="请简要说明，区域所能查看的摄像头位置，此项内容将显示在用户app上。" v-model="form.description" type="textarea" :maxlength="200"></v-textarea>
              <div style="color: #666;">内容请简洁突出</div>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <v-select style="display: inline-block;margin-right: 5px;" v-model="form.status" :options="statusOps"></v-select>
              <span>鹰眼关闭时将会给关联用户发送通知</span>
            </el-form-item>
          </col2-item>
        </col2-block>
    </form-panel>
  </div>
</template>
<script>
import * as allEagleeyeUrl from './api'
import multiSelect from 'components/bussiness/multi-select/multi-select'
import { statusMap, groupTypeOps, statusOps, searchStatusOps } from './map'
import { ChosenListPanel, Col2Block, Col2Item } from 'components/bussiness'
import { normalCommunityParams } from 'common/select2Params'

export default {
  components: {
    multiSelect,
    ChosenListPanel,
    Col2Block,
    Col2Item
  },
  data () {
    return {
      form: {
        groupName: '',
        groupType: undefined,
        communityId: undefined,
        status: 1,
        description: ''
      },
      isShowMultiSelect: false,
      disabledCommunity: false,
      eagleeye: [],
      submitConfig: {
        submitContentType: 'application/x-www-form-urlencoded; charset=UTF-8'
      },
      extraParams: {
        communityId: 'undefined'
      },
      groupTypeOps: groupTypeOps,
      statusOps: statusOps,
      communityParams: normalCommunityParams,
      multiSelectUrl: allEagleeyeUrl.multiSelectUrl,
      responseKey: {
        id: 'id',
        name: 'text'
      },
      submitUrl: this.$route.query.id ? allEagleeyeUrl.updateEagleeyeUrl : allEagleeyeUrl.createEagleeyeUrl,
      multiSelectHeader: [{
        prop: 'deviceName',
        label: '设备名称'
      }, {
        prop: 'cameraName',
        label: '通道名称'
      }, {
        prop: 'status',
        label: '状态'
      }],
      searchelseParams: {
        deviceName: '',
        cameraName: '',
        status: undefined
      },
      searchStatus: searchStatusOps,
      responseParams: {
        id: 'id',
        name: 'cameraName'
      }
    }
  },
  created () {
    let formId = this.$route.query.id
    formId ? this.$setBreadcrumb('编辑') : this.$setBreadcrumb('新增')
    if (formId) {
      this.disabledCommunity = true
      this.getDetailData()
    }
    const userType = String(this.$store.state.userInfo.userType)
    if (userType === '101') {
      this.disabledCommunity = true
      const communityId = this.$store.state.userInfo.communityId
      this.form.communityId = communityId
    }
  },
  methods: {
    multiSelectcallback (items) {
      let _this = this
      _this.eagleeye = items
    },
    getCommunityId (data) {
      if (data) {
        this.extraParams.communityId = data.id
      } else {
        this.extraParams.communityId = 'undefined'
        this.eagleeye = []
      }
    },
    getDetailData () {
      const _this = this
      _this.$axios.post(allEagleeyeUrl.getEagleeyeDetailUrl, { id: this.$route.query.id })
        .then(res => {
          let returnStatus = String(res.status)
          _this.eagleeye = []
          if (returnStatus === '100') {
            const data = res.data
            _this.form.id = data.id
            _this.form.groupName = data.groupName
            _this.form.groupType = data.groupType
            _this.form.communityId = data.communityId
            data.eyes.forEach(item => {
              _this.eagleeye.push({
                id: item.id,
                text: item.NAME
              })
            })
            _this.form.description = data.description
            _this.form.status = data.status
          }
        })
    },
    choseRelationInfo () {
      this.isShowMultiSelect = true
    },
    formatter (item) {
      item.status = statusMap[item.status]
    },
    submitBefore (data) {
      data.eagleeye = JSON.stringify(this.eagleeye.map(item => item.id))
      data.dataObject = data.groupName
      return true
    }
  }
}
</script>
