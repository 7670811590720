var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "eagleeyeForm-container" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: {
              form: _vm.form,
              submitUrl: _vm.submitUrl,
              submitText: "保存",
              submitBefore: _vm.submitBefore,
            },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-block",
            { attrs: { title: "基础信息" } },
            [
              _c(
                "col2-item",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "鹰眼组名称",
                        rules: [
                          {
                            required: true,
                            message: "请输入鹰眼组名称",
                            trigger: "blur",
                          },
                        ],
                        prop: "groupName",
                      },
                    },
                    [
                      _c("v-input", {
                        model: {
                          value: _vm.form.groupName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "groupName", $$v)
                          },
                          expression: "form.groupName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "使用者类型",
                        rules: [
                          {
                            required: true,
                            message: "请选择使用者类型",
                            trigger: "change",
                          },
                        ],
                        prop: "groupType",
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.groupTypeOps },
                        model: {
                          value: _vm.form.groupType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "groupType", $$v)
                          },
                          expression: "form.groupType",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属项目",
                        rules: [
                          {
                            required: true,
                            message: "请选择所属项目",
                            trigger: "change",
                          },
                        ],
                        prop: "communityId",
                      },
                    },
                    [
                      _c(
                        "v-select2",
                        _vm._b(
                          {
                            attrs: {
                              placeholder: "所属项目",
                              disabled: _vm.disabledCommunity,
                            },
                            on: { onChange: _vm.getCommunityId },
                            model: {
                              value: _vm.form.communityId,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "communityId", $$v)
                              },
                              expression: "form.communityId",
                            },
                          },
                          "v-select2",
                          _vm.communityParams,
                          false
                        )
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "摄像头", prop: "eagleeye" } },
                    [
                      _c("chosenListPanel", {
                        attrs: { list: _vm.eagleeye },
                        on: {
                          "update:list": function ($event) {
                            _vm.eagleeye = $event
                          },
                          select: _vm.choseRelationInfo,
                        },
                      }),
                      _c("multiSelect", {
                        attrs: {
                          isShow: _vm.isShowMultiSelect,
                          searchUrl: _vm.multiSelectUrl,
                          headers: _vm.multiSelectHeader,
                          backFill: _vm.eagleeye,
                          searchParams: _vm.searchelseParams,
                          responseParams: _vm.responseParams,
                          responseKey: _vm.responseKey,
                          title: "鹰眼摄像列表",
                          handleData: _vm.formatter,
                          extraParams: _vm.extraParams,
                        },
                        on: {
                          "update:isShow": function ($event) {
                            _vm.isShowMultiSelect = $event
                          },
                          "update:is-show": function ($event) {
                            _vm.isShowMultiSelect = $event
                          },
                          "update:backFill": function ($event) {
                            _vm.eagleeye = $event
                          },
                          "update:back-fill": function ($event) {
                            _vm.eagleeye = $event
                          },
                          callback: _vm.multiSelectcallback,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "searchSlot",
                            fn: function () {
                              return [
                                _c("v-input", {
                                  attrs: { label: "设备名称" },
                                  model: {
                                    value: _vm.searchelseParams.deviceName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchelseParams,
                                        "deviceName",
                                        $$v
                                      )
                                    },
                                    expression: "searchelseParams.deviceName",
                                  },
                                }),
                                _c("v-input", {
                                  attrs: { label: "通道名称" },
                                  model: {
                                    value: _vm.searchelseParams.cameraName,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchelseParams,
                                        "cameraName",
                                        $$v
                                      )
                                    },
                                    expression: "searchelseParams.cameraName",
                                  },
                                }),
                                _c("v-select", {
                                  attrs: {
                                    label: "状态",
                                    options: _vm.searchStatus,
                                  },
                                  model: {
                                    value: _vm.searchelseParams.status,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchelseParams,
                                        "status",
                                        $$v
                                      )
                                    },
                                    expression: "searchelseParams.status",
                                  },
                                }),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "描述",
                        rules: [
                          {
                            required: true,
                            message: "请输入描述",
                            trigger: "blur",
                          },
                        ],
                        prop: "description",
                      },
                    },
                    [
                      _c("v-textarea", {
                        attrs: {
                          placeholder:
                            "请简要说明，区域所能查看的摄像头位置，此项内容将显示在用户app上。",
                          type: "textarea",
                          maxlength: 200,
                        },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                      _c("div", { staticStyle: { color: "#666" } }, [
                        _vm._v("内容请简洁突出"),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c("v-select", {
                        staticStyle: {
                          display: "inline-block",
                          "margin-right": "5px",
                        },
                        attrs: { options: _vm.statusOps },
                        model: {
                          value: _vm.form.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "status", $$v)
                          },
                          expression: "form.status",
                        },
                      }),
                      _c("span", [_vm._v("鹰眼关闭时将会给关联用户发送通知")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }